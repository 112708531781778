import React from "react";

import Layout from "components/Layout";

import { motion } from "framer-motion";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className='notfound'>
        <motion.h1
          drag
          dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        >
          404
        </motion.h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
